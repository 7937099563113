import { Brand } from './typings'

const norair: Brand = {
  clientInfo: {
    displayName: 'Norwegian',
    accountNumberLengths: [16],
    footerDisplay: 'norwegian.com',
    footerLink: 'https://www.norwegian.com/',
    applicationUrl:
      'https://signup.norwegian.com/Home/LogIn?returnUrl=%2F&userOrigin=NotInBooking',
    dsecApigeeClientId: {
      development: 'whsOEOf27SiPqz6HhyUUAAMDXMRO3Upe',
      production: 'zV9HVVkAblJ7nQvGZIgAlhw3sJXjdOIM'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Norwegian credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Norwegian en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'norwegian.com',
    hostname: 'norwegianair.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Norwegian Reward Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Norwegian Reward Card. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of Norwegian.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      dualcard: '1-855-209-2822',
      fraud: '1-855-209-2822',
      customerService: '1-855-209-2822',
      plcc: ''
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@norair/en/html/ess030473.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@norair/en/html/ess030468.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@norair/en/html/ess030467.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@norair/en/html/ess030469.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#34657F'
      },
      secondary: {
        hover: '#34657F'
      },
      background: '#34657F'
    },
    field: {
      icon: '#003d50',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#003d50',
      label: '#003d50'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#3b3c39',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '',
    bodyBackground: '#f2f2f2',
    iconBackground: '#003d50',
    headerBackground: '#3b3c39',
    modalBackground: '#fff',
    headerDivider: '#3b3c39',
    footerDivider: '#003d50',
    contrastingText: '#fff',
    bodyText: '#333',
    languageSelector: '#fff',
    focus: '#003d50',
    link: '#21272e',
    footer: {
      background: '#e3e3e3',
      text: '#333333',
      link: '#333333'
    },
    formBackground: ''
  },
  images: {
    logo: {
      en: '/assets/norair/logo.png',
      es: '/assets/norair/logo.png'
    },
    hero: '/assets/norair/background.jpg',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/norair/promo.png',
      es: '/assets/norair/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default norair
